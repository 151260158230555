import styled from 'styled-components';
import { BREAKPOINTS } from 'utils/constants';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 52px;
  padding: 70px 16px 50px 16px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    padding: 50px 32px 50px 32px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    padding: 100px 125px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 32px;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
  }
`;

export const Header = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 700;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 44px;
    line-height: 48px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 33.33%;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    width: 66.66%;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 52px;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    flex-direction: row;
    column-gap: 26px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    flex-direction: row;
    column-gap: 115px;
  }
`;

export const Column = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    gap: 20px;
  }
`;

export const ColumnHeader = styled.div`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const ColumnText = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  @media screen and (min-width: ${BREAKPOINTS.tablet}) {
    font-size: 17px;
    line-height: 22px;
  }

  @media screen and (min-width: ${BREAKPOINTS.laptop}) {
    font-size: 18px;
    line-height: 28px;
  }
`;
