import { Block } from './blocks';

export const declationsOfNumerals = (value: number, words: string[]): string => {
  const count = Math.abs(value) % 100;
  const num = count % 10;
  if (count > 10 && count < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};

export const blocksToMap = (blocks: Block[]): Record<string, Block> => {
  return blocks.reduce((acc, block) => {
    acc = { ...acc, [block.type]: block };
    return acc;
  }, {});
};
